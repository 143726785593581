import type { Component } from "svelte"
import { sortBy } from 'sort-by-typescript';

type DocFrontMatter = {
  title: string
  description: string
  created: Date
  updated: Date
  toc_depth?: number
}

type RawDocument = {
  default: Component
  metadata: DocFrontMatter
}

const defaults = {
  toc_depth: 3,
}

export type Doc = Required<DocFrontMatter> & {
  slug: string
  rank: number,
  section: string,
  component: Component
}

const raw_docs = import.meta.glob<RawDocument>("./documentation/**/*.svx", { eager: true })

type ParsedPath = {
  section: string,
  section_rank: number,
  rank: number,
  slug: string
}
function parsePath(path: string): ParsedPath {
  // e.g. path './documentation/[head]/01-getting-started.svx

  const relative = path.substring("./documentation/".length)
  // e.g. relative '[head]/01-getting-started.svx

  const splitPos = relative.lastIndexOf("/")
  const rankedSection = relative.substring(0, splitPos)
  const basename = relative.substring(splitPos + 1)

  const sectionSplitPos = rankedSection.indexOf("-")
  const section_rank = Number(rankedSection.substring(0, sectionSplitPos))
  const section = rankedSection.substring(sectionSplitPos + 1)

  const rankSplitPos = basename.indexOf("-")
  const rank = Number(basename.substring(0, rankSplitPos))
  const slug = basename.substring(rankSplitPos + 1, basename.length - 4) //remove the .svx

  return {
    section,
    section_rank,
    rank,
    slug
  }
}

const unsorted_docs: Doc[] = Object.keys(raw_docs).map((key) => {
  // e.g. key: './documentation/[head]/01-getting-started.svx

  const pathParts = parsePath(key)
  const doc = raw_docs[key]

  const metadata = {
    ...doc.metadata,
    created: new Date(doc.metadata.created),
    updated: new Date(doc.metadata.updated),
  }

  // console.log({doc, key, section, rank, slug})
  const retval = {
    ...pathParts,
    ...defaults,
    ...metadata,
    component: doc.default,
  }

  console.log({unsorted_docs: retval})
  return retval
})

export const grouped_docs: Record<string, Doc[]> = Object.groupBy(
  unsorted_docs.sort(sortBy('section_rank', 'rank', 'title')),
  (d) => d.section
) as Record<string, Doc[]>

type DocsTree = {
  head: Doc[]
  section_names: string[]
  sections: Record<string, Doc[]>
  tail: Doc[]
}

function filterRecordByKey<V>(
  obj: Record<string, V>,
  fn: (arg: string) => boolean
): Record<string, V> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => fn(key))
  )
}

export const docs: DocsTree = {
  head: grouped_docs["[head]"],
  section_names: Object.keys(grouped_docs).filter(key => !key.startsWith("[")),
  sections: filterRecordByKey(grouped_docs, key => !key.startsWith("[")),
  tail: grouped_docs["[tail]"]
}

export function docByPath(path: string): Doc | undefined {
  const splitAt = path.indexOf("/")

  if (splitAt === -1) {
    const found =
      (docs.head && docs.head.find(doc => doc.slug === path))
      || (docs.tail && docs.tail.find(doc => doc.slug === path))
    return found  
  } else {
    const section = path.substring(0, splitAt)
    const slug = path.substring(splitAt + 1)

    return docs.sections[section].find(doc => doc.slug === slug)
  }
}

