---
title: "Getting Started"
description: "Getting Started"
created: 2025-01-01
updated: 2025-01-01
---
<script lang="ts">
    import { selected_org } from "$lib/stores/selected_org"
</script>
# Here we are

Your organisation is "{$selected_org.name}"